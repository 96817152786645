<template>
    <div v-if="isLoaded" :key="slug" v-scroll-lock="lockScroll">
        <b-tooltip
            v-if="show && currentStep && tooltipTarget && projectPath === currentStep.path"
            :key="currentStep.target"
            :target="tooltipTarget"
            triggers
            custom-class="wit-tutorial__step"
            :container="tooltipContainer"
            :placement="currentStep.placement"
            :show="show"
            ref="tooltip"
            boundary-padding="-250"
            v-bind:class="{'wit-tutorial__hide': !show}"
        >
            <div class="wit-tutorial__step-header" v-if="currentStep.header">{{ currentStep.header }}</div>
            <div class="wit-tutorial__step-content" v-html="currentStep.tooltipContent"></div>
            <div v-if="showStepError" class="wit-tutorial__step-error">You need to finish this step first</div>
            <div class="wit-tutorial__step-actions">
                <b-button
                    v-if="stepIndex !== 0 && steps.length !== 1 && !currentStep.custom.hidePreviousStepButton"
                    @click="previousStep"
                    variant="warning"
                    :disabled="loadingStep"
                    id="wit-tutorial__step-previous"
                    >Previous</b-button
                >
                <b-button
                    v-if="stepIndex !== steps.length - 1 && !currentStep.custom.hideNext"
                    @click="nextStep"
                    variant="warning"
                    :disabled="loadingStep"
                    id="wit-tutorial__step-next"
                >
                    <span v-if="loadingStep">
                        <i class="fa fa-spinner fa-spin" />
                    </span>
                    <span v-else>Next</span>
                </b-button>
                <b-button v-if="stepIndex === steps.length - 1" @click="finish" variant="warning">Finish</b-button>
                <b-button @click="stop" variant="danger">Stop Tutorial</b-button>
            </div>
        </b-tooltip>

        <WitModal
            v-model="showReset"
            title="You have recently started this tutorial"
            variant="warning"
            customClass="wit-tutorial-modal"
            size="md"
        >
            <div class="wit-tutorial-modal__text">Do you wish to continue with your previous data?</div>
            <b-row class="d-block text-center">
                <b-button variant="success" size="md" @click="continueTutorial">Yes</b-button>
                <span class="pad-buttons" />
                <b-button
                    variant="danger"
                    size="md"
                    @click="() => (startModal.configured ? showStartModal() : resetTutorial())"
                    >No</b-button
                >
            </b-row>
        </WitModal>

        <WitModal
            v-model="startModal.show"
            :title="startModal.title"
            :variant="startModal.variant"
            customClass="wit-tutorial-modal"
            size="md"
        >
            <div class="wit-tutorial-modal__text" v-html="startModal.text"></div>
            <b-row class="d-block text-center">
                <b-button variant="success" size="md" @click="resetTutorial">Start</b-button>
            </b-row>
        </WitModal>

        <WitModal
            v-model="finishModal.show"
            :title="finishModal.title"
            :variant="finishModal.variant"
            customClass="wit-tutorial-modal"
            size="md"
        >
            <div class="wit-tutorial-modal__text" v-html="finishModal.text"></div>
            <b-row class="d-block text-center">
                <b-button variant="success" size="md" @click="closeFinishModal">Finish</b-button>
            </b-row>
        </WitModal>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import EventBus from '../eventBus'
import WitModal from '@/components/Modals/WitModal'

const cloneDeep = require('lodash.clonedeep')
const isEqual = require('fast-deep-equal')

export default {
    components: {
        WitModal,
    },
    data() {
        return {
            options: {
                highlight: true,
            },
            showStepError: false,
            waiting: null,
            isLoaded: false,
            showReset: false,
            lockScroll: false,
            overlayTarget: {
                width: 0,
                height: 0,
            },
            overlayCounter: 0,
            loadingStep: false,
            startModal: {
                show: false,
                configured: false,
                title: '',
                variant: '',
                text: '',
            },
            finishModal: {
                show: false,
                configured: false,
                title: '',
                variant: '',
                text: '',
            },
        }
    },
    computed: {
        ...mapGetters({
            asideWidth: 'aside/width',
            asideCollapsed: 'aside/collapsed',
            docs: 'tutorial/docs',
            sidebarWidth: 'sidebar/width',
            sidebarCollapsed: 'sidebar/collapsed',
        }),
        slug: {
            get() {
                return this.$store.state.tutorial.slug
            },

            set(value) {
                this.$store.commit('tutorial/SET_SLUG', value)
            },
        },
        startSlug: {
            get() {
                return this.$store.state.tutorial.startSlug
            },

            set(value) {
                this.$store.commit('tutorial/SET_STARTSLUG', value)
            },
        },
        previousStepIndex: {
            get() {
                return this.$store.state.tutorial.previousStepIndex
            },

            set(value) {
                this.$store.commit('tutorial/SET_PREVIOUSSTEPINDEX', value)
            },
        },
        stepIndex: {
            get() {
                return this.$store.state.tutorial.stepIndex
            },

            set(value) {
                this.$store.commit('tutorial/SET_STEPINDEX', value)
            },
        },
        steps: {
            get() {
                return this.$store.state.tutorial.steps
            },

            set(value) {
                this.$store.commit('tutorial/SET_STEPS', value)
            },
        },
        forms: {
            get() {
                return this.$store.state.tutorial.forms
            },

            set(value) {
                this.$store.commit('tutorial/SET_FORMS', value)
            },
        },
        show: {
            get() {
                return this.$store.state.tutorial.show
            },

            set(value) {
                this.$store.commit('tutorial/SET_SHOW', value)
            },
        },
        codelabs: {
            get() {
                return this.$store.state.tutorial.codelabs
            },

            set(value) {
                this.$store.commit('tutorial/SET_CODELABS', value)
            },
        },
        tutorials: {
            get() {
                return this.$store.state.tutorial.tutorials
            },

            set(value) {
                this.$store.commit('tutorial/SET_TUTORIALS', value)
            },
        },
        quickHelp: {
            get() {
                return this.$store.state.tutorial.quickHelp
            },

            set(value) {
                this.$store.commit('tutorial/SET_QUICKHELP', value)
            },
        },
        showQuickHelp: {
            get() {
                return this.$store.state.tutorial.showQuickHelp
            },

            set(value) {
                this.$store.commit('tutorial/SET_SHOWQUICKHELP', value)
            },
        },
        tutorialStep: {
            get() {
                return this.$store.state.tutorial.tutorialStep
            },

            set(value) {
                this.$store.commit('tutorial/SET_TUTORIALSTEP', value)
            },
        },
        addPadding: {
            get() {
                return this.$store.state.tutorial.addPadding
            },

            set(value) {
                this.$store.commit('tutorial/SET_ADDPADDING', value)
            },
        },

        tooltipTarget() {
            return this.steps.length > 0 && this.currentStep ? document.querySelector(this.currentStep.target) : null
        },

        isTooltipVisible() {
            return this.tooltipTarget
                ? Boolean(
                      this.tooltipTarget.offsetWidth ||
                          this.tooltipTarget.offsetHeight ||
                          this.tooltipTarget.getClientRects().length
                  )
                : false
        },

        isTooltipInApp() {
            return this.tooltipTarget ? this.tooltipContainer.contains(this.tooltipTarget) : false
        },

        tooltipContainer() {
            return document.querySelector('div.app')
        },

        currentStep() {
            return this.steps[this.stepIndex]
        },

        projectPath() {
            const {path} = this.$route
            const noSlashPath = path.substr(0, 1) === '/' ? path.substr(1) : path
            const [p, projectId, ..._path] = noSlashPath.split('/')

            return `/${_path.join('/')}`
        },
    },
    watch: {
        $route(to, from) {
            this.removeOverlay()

            if (this.steps.length > 0) {
                this.showStepError = false
                this.checkIfStepChanged()
            }
            this.findQuickHelp()
        },
        asideWidth() {
            setTimeout(() => this.triggerHighlight(true), 300)
        },
        asideCollapsed() {
            setTimeout(() => this.triggerHighlight(true), 300)
        },
        sidebarWidth() {
            setTimeout(() => this.triggerHighlight(true), 300)
        },
        sidebarCollapsed() {
            setTimeout(() => this.triggerHighlight(true), 300)
        },
        async stepIndex() {
            this.onStepIndexChange()
        },
        show() {
            const block = document.querySelector('.wit-tutorial__overlay-block-hidden')
            if (block && !this.show) {
                block.classList.add('wit-tutorial__overlay-block-hidden-hide')
            }
            if (block && this.show) {
                block.classList.remove('wit-tutorial__overlay-block-hidden-hide')
            }
        },
        startSlug() {
            if (this.startSlug) {
                this.startTutorial()
            }
        },
    },
    async created() {
        window.addEventListener('beforeunload', this.saveTutorial)
        EventBus.$on('triggerHighlight', this.triggerHighlight)
        EventBus.$on('changePageFromForm', this.handleChangePage)
        EventBus.$on('closeTutorial', this.stop)
        EventBus.$on('workflowShow', this.onWorkflowShow)
        EventBus.$on('workflowHide', this.onWorkflowHide)

        this.getTutorials()
        this.findQuickHelp()

        if (this.startSlug) {
            this.startTutorial()
        }
    },
    async mounted() {
        this.isLoaded = true

        if (this.currentStep) {
            this.onReload()
            this.tutorialStep = this.currentStep.asideContent
            EventBus.$emit('showTutorialStep')
        }
    },
    beforeDestroy() {
        if (this.steps.length > 0 && this.currentStep) {
            this.saveTutorial()
        }
        EventBus.$off('triggerHighlight', this.triggerHighlight)
        EventBus.$off('changePageFromForm', this.handleChangePage)
        EventBus.$off('closeTutorial', this.stop)
        EventBus.$off('workflowShow', this.onWorkflowShow)
        EventBus.$off('workflowHide', this.onWorkflowHide)
        window.removeEventListener('beforeunload', this.saveTutorial)
        if (this.highlightInterval) {
            clearInterval(this.highlightInterval)
            this.highlightInterval = null
            this.overlayCounter = 0
        }
        this.addPadding = false
    },
    methods: {
        removeOverlay() {
            const app = document.querySelector('.app')
            const overlay = document.querySelector('.wit-tutorial__overlay')

            if (overlay) {
                app.removeChild(overlay)
            }

            this.lockScroll = false
        },

        applyOverlay() {
            const target =
                this.currentStep.custom && this.currentStep.custom.overlayTarget
                    ? document.querySelector(this.currentStep.custom.overlayTarget)
                    : this.tooltipTarget

            if (this.tooltipTarget && this.isTooltipVisible && target) {
                this.lockScroll = true
                const app = document.querySelector('.app')

                const overlay = document.createElement('div')
                overlay.classList.add('wit-tutorial__overlay')

                const overlayTop = document.createElement('div')
                overlayTop.classList.add('wit-tutorial__overlay-block')
                overlayTop.style.width = `${app.offsetWidth}px`
                overlayTop.style.height = `${window.pageYOffset + target.getBoundingClientRect().top}px`

                const overlayMid = document.createElement('div')
                overlayMid.classList.add('wit-tutorial__overlay-mid')

                const overlayLeft = document.createElement('div')
                overlayLeft.style.width = `${window.pageXOffset + target.getBoundingClientRect().left}px`
                overlayLeft.style.height = `${target.offsetHeight}px`
                overlayLeft.classList.add('wit-tutorial__overlay-block')
                overlayLeft.classList.add('wit-tutorial__overlay-block-left')

                const overlayCenter = document.createElement('div')
                overlayCenter.style.width = `${target.offsetWidth}px`
                overlayCenter.style.height = `${target.offsetHeight}px`
                this.overlayTarget = {
                    width: target.offsetWidth,
                    height: target.offsetHeight,
                    offsetHeight: window.pageYOffset + target.getBoundingClientRect().top,
                }
                overlayCenter.classList.add('wit-tutorial__overlay-block-hidden')
                if (!this.show) {
                    overlayCenter.classList.add('wit-tutorial__overlay-block-hidden-hide')
                }

                const overlayRight = document.createElement('div')
                overlayRight.style.width = `${app.offsetWidth -
                    window.pageXOffset -
                    target.getBoundingClientRect().left -
                    target.offsetWidth}px`
                overlayRight.style.height = `${target.offsetHeight}px`
                overlayRight.classList.add('wit-tutorial__overlay-block')
                overlayRight.classList.add('wit-tutorial__overlay-block-right')

                overlayMid.appendChild(overlayLeft)
                overlayMid.appendChild(overlayCenter)
                overlayMid.appendChild(overlayRight)

                const overlayBot = document.createElement('div')
                overlayBot.classList.add('wit-tutorial__overlay-block')
                overlayBot.style.width = `${app.offsetWidth}px`
                // overlayBot.style.height = `${app.offsetHeight -
                //     window.pageYOffset -
                //     target.getBoundingClientRect().top -
                //     target.offsetHeight}px`
                overlayBot.style.height = `${app.offsetHeight +
                    300 -
                    window.pageYOffset -
                    target.getBoundingClientRect().top -
                    target.offsetHeight}px`

                overlay.appendChild(overlayTop)
                overlay.appendChild(overlayMid)
                overlay.appendChild(overlayBot)

                app.appendChild(overlay)
            }
        },

        onWorkflowShow() {
            if (this.steps.length > 0) {
                if (
                    !this.currentStep.custom.inWorkflowModal &&
                    this.steps[this.stepIndex + 1] &&
                    this.steps[this.stepIndex + 1].custom.inWorkflowModal
                ) {
                    this.changeStep(this.stepIndex + 1)
                } else if (this.currentStep.custom.inWorkflowModal) {
                    this.$forceUpdate()
                    this.highlightTarget()
                }
            }
        },

        onWorkflowHide() {
            if (
                this.currentStep &&
                this.currentStep.custom.inWorkflowModal &&
                this.steps[this.stepIndex + 1] &&
                !this.steps[this.stepIndex + 1].custom.inWorkflowModal
            ) {
                this.changeStep(this.stepIndex + 1)
            }
        },

        async saveTutorial() {
            if (this.steps.length > 0) {
                let forms = []

                if (this.currentStep) {
                    const promises = this.steps.map(async step => {
                        if (
                            step.path === this.currentStep.path &&
                            step.custom.formId &&
                            this.steps.findIndex(el => el.custom.formId === step.custom.formId) ===
                                this.steps.findIndex(el => el.target === step.target)
                        ) {
                            try {
                                const data = new Promise((resolve, reject) => {
                                    EventBus.$emit(`saveForm-${step.custom.formId}`, resolve)
                                    setTimeout(() => reject(`could not save form ${step.custom.formId}`), 1500)
                                })

                                return {
                                    formId: step.custom.formId,
                                    data: await data,
                                }
                            } catch {}
                        }
                    })

                    forms = (await Promise.all(promises)).filter(Boolean)
                }

                await this.$store.commit(
                    'tutorial/UPSERT',
                    cloneDeep({
                        slug: this.slug,
                        data: {
                            previousStepIndex: this.previousStepIndex,
                            stepIndex: this.stepIndex,
                            steps: this.steps,
                            show: this.show,
                            tutorialStep: this.tutorialStep,
                        },
                        forms,
                    })
                )
            }
        },

        async continueTutorial() {
            this.show = false

            const {
                data: {stepIndex, show, ...data},
                forms,
            } = this.tutorialFound

            Object.keys(data).forEach(key => (this[key] = data[key]))

            if (this.currentStep.path !== this.projectPath) {
                await new Promise(resolve => this.$projectRouter.push(this.currentStep.path, resolve))
            }

            if (this.currentStep.custom.inWorkflowModal) {
                const step = this.steps
                    .slice(0, stepIndex)
                    .filter(el => !el.custom.inWorkflowModal)
                    .slice(-1)[0]

                const index = this.steps.findIndex(el => el.target === step.target)

                this.changeStep(index)
            } else {
                this.changeStep(stepIndex)
            }

            this.forms = forms

            this.highlightTarget()
            this.showReset = false
            this.show = true
        },

        async resetTutorial() {
            try {
                this.startModal.show = false
                this.forms = []
                this.steps = this.tempSteps
                this.show = false

                this.stepIndex = 0
                if (this.steps[0].path !== this.projectPath) {
                    await new Promise((resolve, reject) =>
                        this.$projectRouter.push(this.steps[0].path, resolve, reject)
                    )
                }

                EventBus.$emit('tutorialStart')
                this.showReset = false
                this.show = true

                this.onStepIndexChange()
            } catch (e) {
                console.error(e)
            }
        },

        onStepIndexChange() {
            if (this.highlightInterval) {
                clearInterval(this.highlightInterval)
                this.highlightInterval = null
                this.overlayCounter = 0
            }

            if (this.currentStep) {
                this.highlightInterval = setInterval(this.highlightTargetCheck, 250)
            }

            this.highlightTarget()
        },

        findQuickHelp() {
            const route = this.getRoute()
            const doc = this.docs.find(el => el.witcloudPath.includes(route))

            if (doc) {
                this.quickHelp = doc.link
                this.showQuickHelp = true
            } else {
                this.showQuickHelp = false
            }
        },

        getRoute() {
            return Object.entries(this.$route.params).reduce((acc, [key, value]) => {
                return acc.replace(value, key)
            }, this.projectPath)
        },

        async getTutorials() {
            const {
                data: {stories},
            } = await this.$storyblok.get(`cdn/stories`, {
                starts_with: 'tutorials',
                is_start_page: false,
                per_page: 100,
                excluding_fields: 'steps',
                version: ['development', 'local'].includes(process.env.NODE_ENV) ? 'draft' : 'published',
            })

            this.tutorials = stories.map(story => ({
                slug: story.full_slug.replace('tutorials/', ''),
                name: story.content.name,
            }))
        },

        async startTutorial() {
            try {
                this.slug = this.startSlug
                this.startSlug = ''
                this.tempSteps = []
                this.steps = []

                if (this.mutationObserver) {
                    this.mutationObserver.disconnect()
                    this.mutationObserver = null
                }

                const {
                    data: {story},
                } = await this.$storyblok.get(`cdn/stories/tutorials/${this.slug}`, {
                    version: ['development', 'local'].includes(process.env.NODE_ENV) ? 'draft' : 'published',
                })

                this.tempSteps = story.content.steps.map(step => ({
                    target: step.target,
                    placement: step.placement,
                    header: step.header,
                    tooltipContent: step.tooltipContent
                        ? this.$storyblok.richTextResolver.render(step.tooltipContent)
                        : '',
                    path: step.path,
                    custom: {
                        formId: step.formId,
                        page: Number(step.formStep) - 1,
                        hidePreviousStepButton: step.hidePreviousStepButton,
                        hideNext: step.hideNext,
                        emulateClick: step.emulateClick,
                        inWorkflowModal: step.inWorkflowModal,
                        overlayTarget: step.overlayTarget,
                        validateSelector: step.validateSelector,
                    },
                }))

                if (story.content.startModal && story.content.startModal[0]) {
                    this.startModal = {
                        show: false,
                        configured: true,
                        title: story.content.startModal[0].title,
                        variant: story.content.startModal[0].variant,
                        text:
                            '<div>' +
                            this.$storyblok.richTextResolver.render(story.content.startModal[0].text) +
                            '</div>',
                    }
                }

                if (story.content.finishModal && story.content.finishModal[0]) {
                    this.finishModal = {
                        show: false,
                        configured: true,
                        title: story.content.finishModal[0].title,
                        variant: story.content.finishModal[0].variant,
                        text:
                            '<div>' +
                            this.$storyblok.richTextResolver.render(story.content.finishModal[0].text) +
                            '</div>',
                    }
                }

                if (this.startModal.configured) {
                    this.startModal.show = true
                } else {
                    this.resetTutorial()
                }
            } catch (e) {
                console.error(e)
            }
        },

        showStartModal() {
            this.showReset = false
            this.startModal.show = true
        },

        async previousStep() {
            this.showStepError = false

            const previousPath = this.steps[this.stepIndex - 1].path
            if (previousPath !== this.currentStep.path) {
                await new Promise((resolve, reject) => this.$projectRouter.push(previousPath, resolve, reject))
            } else {
                if (this.steps[this.stepIndex - 1].custom.formId === this.currentStep.custom.formId) {
                    const previousPage = this.steps[this.stepIndex - 1].custom.page
                    const currentPage = this.currentStep.custom.page

                    if (currentPage !== previousPage) {
                        if (previousPage !== currentPage - 1) {
                            this.removeOverlay()
                        }

                        const pageChanged = new Promise((resolve, reject) => {
                            EventBus.$emit(
                                `changePageFromStep-${this.currentStep.custom.formId}`,
                                previousPage,
                                resolve,
                                reject
                            )
                        })

                        pageChanged
                            .then(() => {
                                this.showStepError = false
                                if (previousPage === currentPage - 1) {
                                    setTimeout(() => this.changeStep(this.stepIndex - 1), 250)
                                }
                            })
                            .catch(err => {
                                this.showStepError = true
                                this.changeStep(this.previousStepIndex)
                            })
                    } else {
                        this.showStepError = false
                        this.changeStep(this.stepIndex - 1)
                    }
                } else {
                    if (
                        this.currentStep.custom.inWorkflowModal &&
                        !this.steps[this.stepIndex - 1].custom.inWorkflowModal
                    ) {
                        EventBus.$emit('closeWorkflowModal', true)
                    }
                    this.showStepError = false
                    this.changeStep(this.stepIndex - 1)
                }
            }
        },

        async onReload() {
            this.tutorialFound = await this.$store.dispatch('tutorial/find', this.slug)
            const {
                data: {stepIndex, show, ...data},
                forms,
            } = this.tutorialFound

            Object.keys(data).forEach(key => (this[key] = data[key]))
            this.forms = forms

            if (this.currentStep && this.projectPath === this.currentStep.path) {
                if (this.currentStep.custom.formId) {
                    const currentPage = this.currentStep.custom.page

                    if (currentPage !== 0) {
                        const pageChanged = new Promise((resolve, reject) => {
                            EventBus.$emit(
                                `changePageFromStep-${this.currentStep.custom.formId}`,
                                currentPage,
                                resolve,
                                reject
                            )
                        })

                        pageChanged
                            .then(index => {
                                this.showStepError = false
                                const stepFoundIndex = this.steps.findIndex(el => el.custom.page === index)
                            })
                            .catch(e => {
                                this.showStepError = true
                                this.stop()
                            })
                    } else {
                        this.changeStep(this.stepIndex)
                    }
                } else {
                    this.changeStep(this.stepIndex)
                }
            } else {
                this.stop()
            }
        },

        async nextStep() {
            this.showStepError = false

            if (this.currentStep && this.currentStep.custom.emulateClick) {
                this.tooltipTarget.dispatchEvent(new Event('click'))
                return
            }

            if (this.currentStep.custom.validateSelector) {
                try {
                    this.loadingStep = true
                    await new Promise((resolve, reject) => {
                        this.validateStepInterval = setInterval(() => {
                            const valid = document.querySelector(this.currentStep.custom.validateSelector)

                            if (valid) {
                                clearInterval(this.validateStepInterval)
                                this.loadingStep = false
                                resolve()
                            }
                        }, 100)

                        setTimeout(() => {
                            clearInterval(this.validateStepInterval)
                            reject('could not change step')
                        }, 2000)
                    })
                } catch {
                    this.loadingStep = false
                    this.showStepError = true
                    return
                }
            }

            const nextPath = this.steps[this.stepIndex + 1].path

            if (nextPath !== this.currentStep.path) {
                await new Promise((resolve, reject) => this.$projectRouter.push(nextPath, resolve, reject))
            } else {
                if (this.steps[this.stepIndex + 1].custom.formId === this.currentStep.custom.formId) {
                    const nextPage = this.steps[this.stepIndex + 1].custom.page
                    const currentPage = this.currentStep.custom.page

                    if (currentPage !== nextPage && nextPage >= 1) {
                        if (nextPage !== currentPage + 1) {
                            this.removeOverlay()
                        }

                        const pageChanged = new Promise((resolve, reject) => {
                            EventBus.$emit(
                                `changePageFromStep-${this.currentStep.custom.formId}`,
                                nextPage,
                                resolve,
                                reject
                            )
                        })

                        pageChanged
                            .then(index => {
                                this.showStepError = false
                                const stepFoundIndex = this.steps.findIndex(el => el.custom.page === index)
                                setTimeout(() => this.changeStep(stepFoundIndex), 250)
                            })
                            .catch(e => {
                                this.showStepError = true
                                this.changeStep(this.previousStepIndex)
                            })
                    } else {
                        this.showStepError = false
                        this.changeStep(this.stepIndex + 1)
                    }
                } else {
                    if (
                        this.currentStep.custom.inWorkflowModal &&
                        !this.steps[this.stepIndex + 1].custom.inWorkflowModal
                    ) {
                        EventBus.$emit('closeWorkflowModal', true)
                    }
                    this.showStepError = false
                    this.changeStep(this.stepIndex + 1)
                }
            }
        },

        async changeStep(index) {
            this.show = false
            const newStep = this.steps[index]

            if (newStep) {
                try {
                    await new Promise((resolve, reject) => {
                        this.changeStepInterval = setInterval(() => {
                            const newTarget = document.querySelector(newStep.target)

                            if (newTarget) {
                                clearInterval(this.changeStepInterval)
                                resolve()
                            }
                        }, 100)

                        setTimeout(() => {
                            clearInterval(this.changeStepInterval)
                            reject('could not change step')
                        }, 1000)
                    })
                } catch {}

                this.previousStepIndex = this.stepIndex
                this.stepIndex = index
                this.$forceUpdate()
                this.highlightTarget()
                this.scrollToTarget()

                setTimeout(() => (this.show = true), 200)
            } else {
                this.addPadding = false
                this.removeOverlay()
            }
        },

        checkIfStepChanged() {
            if (this.currentStep && this.projectPath === this.currentStep.path) {
                this.highlightTarget()
            } else {
                const nextStep = this.steps[this.stepIndex + 1]
                const previousStep = this.steps[this.stepIndex - 1]
                const stop = {
                    next: false,
                    previous: false,
                }

                if (nextStep) {
                    const [currentPath] = this.projectPath.split('?')
                    const shouldBePath = nextStep.path

                    if (currentPath === shouldBePath) {
                        this.changeStep(this.stepIndex + 1)
                    } else {
                        stop.next = true
                    }
                } else {
                    stop.next = true
                }

                if (previousStep) {
                    const [currentPath] = this.projectPath.split('?')
                    const shouldBePath = previousStep.path

                    if (currentPath === shouldBePath) {
                        this.changeStep(this.stepIndex - 1)
                    } else {
                        stop.previous = true
                    }
                } else {
                    stop.previous = true
                }

                if (stop.next && stop.previous) {
                    this.stop()
                }
            }
        },

        finish() {
            if (this.currentStep && this.currentStep.custom.emulateClick) {
                this.tooltipTarget.click()
            }

            this.$store.commit('tutorial/UPSERT', {
                slug: this.slug,
                data: {},
            })

            this.show = false
            this.slug = ''
            this.tutorialStep = ''
            this.forms = []

            if (this.currentStep) {
                const step = document.querySelector(this.currentStep.target)
                if (step) {
                    this.removeOverlay()
                    step.classList.remove('wit-tutorial__highlighted')
                }
            }

            this.steps = []
            this.stepIndex = 0
            this.previousStepIndex = -1
            this.addPadding = false
            document.body.style.overflowY = 'auto'

            if (this.finishModal.configured) {
                this.finishModal.show = true
            } else {
                this.closeFinishModal()
            }
        },

        closeFinishModal() {
            this.startModal = {
                show: false,
                configured: false,
                title: '',
                variant: '',
                text: '',
            }
            this.finishModal = {
                show: false,
                configured: false,
                title: '',
                variant: '',
                text: '',
            }
        },

        async stop() {
            try {
                await this.saveTutorial()
            } catch {}

            try {
                this.show = false
                this.slug = ''
                this.tutorialStep = ''
                this.forms = []

                if (this.currentStep) {
                    const step = document.querySelector(this.currentStep.target)
                    if (step) {
                        this.removeOverlay()
                        step.classList.remove('wit-tutorial__highlighted')
                    }
                }

                this.steps = []
                this.stepIndex = 0
                this.previousStepIndex = -1
                this.addPadding = false
                document.body.style.overflowY = 'auto'
            } catch {}
        },

        highlightTarget() {
            if (this.previousStepIndex > -1 && this.previousStepIndex < this.steps.length) {
                const previousStepTarget = document.querySelector(this.steps[this.previousStepIndex].target)
                if (previousStepTarget) {
                    previousStepTarget.classList.remove('wit-tutorial__highlighted')
                    this.removeOverlay()
                }
            }

            if (this.currentStep) {
                const currentTarget = document.querySelector(this.currentStep.target)
                if (currentTarget) {
                    currentTarget.classList.add('wit-tutorial__highlighted')
                    this.removeOverlay()
                    this.applyOverlay()
                }
            }
        },

        highlightTargetCheck() {
            document.body.style.overflowY = 'hidden'
            const currentOverlayTarget = {...this.overlayTarget}

            this.scrollToTarget()
            this.highlightTarget()

            const newOverlayTarget = {...this.overlayTarget}

            if (isEqual(currentOverlayTarget, newOverlayTarget)) {
                this.overlayCounter++

                if (this.overlayCounter === 10) {
                    clearInterval(this.highlightInterval)
                    this.highlightInterval = null
                    this.overlayCounter = 0

                    if (this.isTooltipInApp) {
                        document.body.style.overflowY = 'auto'
                    }
                }
            } else {
                document.body.style.overflowY = 'hidden'
                this.overlayCounter = 0
            }
        },

        triggerHighlight() {
            if (this.highlightInterval) {
                clearInterval(this.highlightInterval)
                this.highlightInterval = null
                this.overlayCounter = 0
            }

            if (this.currentStep) {
                this.highlightInterval = setInterval(this.highlightTargetCheck, 250)
            }
        },

        scrollToTarget() {
            if (this.tooltipTarget) {
                if (this.isTooltipInApp && !this.isElementInViewport(this.tooltipTarget)) {
                    this.addPadding = true
                    this.scrollIntoView()
                }
            }
        },

        handleChangePage(page, modalIndex) {
            if (this.steps.length > 0) {
                if (this.currentStep.custom.formId) {
                    const steps = this.steps
                        .map((step, index) =>
                            step.custom.page === page && step.custom.formId === this.currentStep.custom.formId
                                ? {
                                      ...step,
                                      index,
                                  }
                                : null
                        )
                        .filter(Boolean)

                    if (steps.length > 0) {
                        if (this.currentStep && this.currentStep.custom.page >= page) {
                            this.changeStep(steps[steps.length - 1].index)
                        } else {
                            const {index} = modalIndex
                                ? steps.filter(({target}) => target.endsWith(`-${modalIndex}`))[0]
                                : steps[0]
                            this.changeStep(index)
                        }
                    }
                }
            }
        },

        isElementInViewport(el) {
            if (el) {
                const rect = el.getBoundingClientRect()

                return (
                    rect.top >= 80 &&
                    rect.left >= 0 &&
                    rect.top <= (window.innerHeight || document.documentElement.clientHeight) * (2 / 3) &&
                    rect.bottom < (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                )
            }
        },

        scrollIntoView() {
            const middle = this.tooltipTarget.getBoundingClientRect().top + window.pageYOffset - window.innerHeight / 2

            try {
                window.scrollTo({
                    top: middle,
                    left: 0,
                    behavior: 'smooth',
                })
            } catch {
                window.scrollTo(0, middle)
            }
        },
    },
}
</script>

<style lang="scss">
.wit-tutorial__highlighted {
    border-radius: 5px;
    box-shadow: 0px 0px 15px 5px #f2b929;
    z-index: 1030;
}

.tooltip.wit-tutorial__step.wit-tutorial__hide {
    display: none;
    visibility: hidden;
}

.tooltip.wit-tutorial__step {
    opacity: 1;
    z-index: 3003;

    .tooltip-inner {
        background-color: #29363d;
        border-radius: 5px;
        min-width: 150px;

        .wit-tutorial__step-header {
            font-size: 0.85rem;
            font-weight: 600;
            padding: 0.25rem;
            border-bottom: 1px solid white;
        }
        .wit-tutorial__step-content {
            font-size: 0.85rem;
            padding: 0.25rem;
        }
        .wit-tutorial__step-error {
            font-size: 0.7rem;
            padding: 0.25rem;
            color: red;
        }
        .wit-tutorial__step-actions {
            padding: 0.25rem;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-evenly;
            margin: 0 -5px;

            .btn {
                margin: 0 5px;
                margin-bottom: 5px;
            }

            #wit-tutorial__step-previous {
                opacity: 0.75;
            }
        }
    }
}

.tooltip.wit-tutorial__step[x-placement^='bottom'] {
    .arrow::before {
        border-bottom-color: #29363d;
    }
}

.tooltip.wit-tutorial__step[x-placement^='top'] {
    .arrow::before {
        border-top-color: #29363d;
    }
}

.tooltip.wit-tutorial__step[x-placement^='left'] {
    .arrow::before {
        border-left-color: #29363d;
    }
}

.tooltip.wit-tutorial__step[x-placement^='right'] {
    .arrow::before {
        border-right-color: #29363d;
    }
}

.wit-tutorial__overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3002;
    pointer-events: none;

    .wit-tutorial__overlay-mid {
        display: flex;
        justify-content: center;

        .wit-tutorial__overlay-block-hidden {
            background-color: transparent;
            border-radius: 5px;
        }

        .wit-tutorial__overlay-block-hidden-hide {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }

    .wit-tutorial__overlay-block {
        background-color: rgba(0, 0, 0, 0.3);
        pointer-events: auto;
    }
}

// .wit-tutorial__page-locked {
//     pointer-events: none;

//     .wit-tutorial__highlighted {
//         pointer-events: auto;
//     }
// }
</style>
