<template>
    <AppHeaderDropdown right no-caret>
        <template slot="header">
            {{ this.$auth.user().email }}
            <img src="img/avatars/user.png" class="img-avatar" alt="admin@bootstrapmaster.com" />
        </template>

        <template slot="dropdown">
            <b-dropdown-header tag="div" class="text-center">
                <strong>Settings</strong>
            </b-dropdown-header>
            <b-dropdown-item v-if="!$auth.check()">
                <router-link to="/auth/login">Login</router-link>
            </b-dropdown-item>
            <b-dropdown-item v-else @click="logout()"> <i class="fa fa-lock" /> Logout </b-dropdown-item>
        </template>
    </AppHeaderDropdown>
</template>

<script>
import {HeaderDropdown as AppHeaderDropdown} from '@coreui/vue'
export default {
    components: {
        AppHeaderDropdown,
    },
    created() {
        if (this.$auth.check() && !this.$auth.user().email) {
            this.$auth.fetch()
        }
    },
    methods: {
        async logout() {
            this.$store.commit('loading/PROCESSING', `Logging out...`)

            try {
                localStorage.removeItem('refreshToken')
                this.$store.commit('project/SET_PROJECTS', [])
                await this.$auth.logout()
            } catch {}

            this.$store.commit('loading/PROCESSED')
        },
    },
}
</script>
