<template>
    <MountingPortal mountTo="body" append>
        <ul class="search-result" v-show="localOpen" :style="position" v-clickaway="close" :id="id">
            <template v-if="result.length > 0">
                <project-router-link v-for="(item, idx) in result" :key="idx" :to="getItemRoute(item)">
                    <li class="search-result__option">
                        <div class="search-result__option-label">{{ item[label] }}</div>
                        <div class="search-result__option-type">
                            <span v-if="item.index.includes('workflow')">Workflow</span>
                            <span v-else>{{ item.type }} - {{ item.subtype }}</span>
                        </div>
                    </li>
                </project-router-link>
                <li v-if="loading" class="search-result__loading">
                    <i class="fa fa-spinner fa-spin" />
                </li>
            </template>
            <template v-else>
                <li class="search-result__no-option">No results matching {{ query }}.</li>
            </template>
        </ul>
    </MountingPortal>
</template>

<script>
import {MountingPortal} from 'portal-vue'
import {directive as clickaway} from 'vue-clickaway'

import {collectTypes} from '@/shared/collectTypes'
import {processTypes} from '@/shared/processTypes'
import {reportTypes} from '@/shared/reportTypes'

export default {
    components: {
        MountingPortal,
    },
    directives: {
        clickaway,
    },
    props: {
        result: {
            type: Array,
        },
        query: {
            type: String,
        },
        open: {
            type: Boolean,
        },
        position: {
            type: Object,
        },
        loading: {
            type: Boolean,
        },
        label: {
            type: String,
            default: 'name',
        },
    },
    data() {
        return {
            id: this.$randomId(),
            localOpen: '',
            openTimestamp: null,
        }
    },
    computed: {
        types() {
            return [...collectTypes, ...processTypes, ...reportTypes]
        },
    },
    watch: {
        open() {
            this.onOpenChange()
        },
    },
    created() {
        this.onOpenChange()
    },
    beforeDestroy() {
        this.updateListener()
    },
    methods: {
        onOpenChange() {
            if (this.open !== this.localOpen) {
                this.localOpen = this.open
            }

            this.updateListener()
        },

        updateListener() {
            const list = document.getElementById(this.id)
            if (list) {
                if (this.open) {
                    this.openTimestamp = Date.now()
                    try {
                        list.addEventListener('scroll', this.onScroll, {passive: true})
                    } catch {
                        list.addEventListener('scroll', this.onScroll)
                    }
                } else {
                    this.openTimestamp = null
                    list.scrollTop = 0
                    list.removeEventListener('scroll', this.onScroll)
                }
            }
        },

        close() {
            if (
                !this.openTimestamp ||
                (this.openTimestamp && this.openTimestamp + 250 < Date.now() && this.localOpen)
            ) {
                this.localOpen = false
                this.$emit('update:open', this.localOpen)
            }
        },

        onScroll(event) {
            const element = event.target
            if (element.scrollHeight - element.scrollTop === element.clientHeight) {
                this.$emit('scrollEnd')
            }
        },

        getItemRoute(item) {
            if (item.index.includes('collect') || item.index.includes('process') || item.index.includes('report')) {
                const type = this.types.find(el => el.type === item.type && el.subtype === item.subtype)

                if (type) {
                    if (type.subtype === 'Google Analytics Custom Goals') {
                        return `${type.link.replace('/create', `/${item.pipelineRef.id}`)}/list`
                    } else {
                        return type.link.replace('/create', `/${item.objectID}`).replace('/source', `/${item.objectID}`)
                    }
                } else return ''
            } else if (item.index.includes('workflow')) {
                return `/workflow/${item.objectID}`
            } else return ''
        },
    },
}
</script>

<style lang="scss">
.search-result {
    z-index: 3003;
    padding: 5px 0;
    list-style: none;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 400px;
    background-color: white;
    box-shadow: 0px 3px 6px 0px #d9d9d9;
    border: 1px solid rgba(60, 60, 60, 0.26);
    border-top-style: none;
    border-radius: 0 0 4px 4px;

    a {
        text-decoration: none;
        color: inherit;
    }

    li {
        word-break: break-all;
        overflow-wrap: break-word;
        overflow: hidden;
        white-space: normal;
        padding: 3px 20px;
        padding-top: 7px;
        padding-bottom: 7px;
        line-height: 1.42857143;
    }

    .search-result__no-option {
        text-align: center;
    }

    .search-result__loading {
        text-align: center;
    }

    .search-result__option {
        cursor: pointer;

        .search-result__option-type {
            font-size: 0.7rem;
        }
    }

    .search-result__option:hover {
        background-color: #20a8d8;
        color: white;
    }
}
</style>
