<template>
    <b-modal
        v-model="showModal"
        :size="size"
        centered
        hide-header
        hide-footer
        modal-class="image-popup__modal"
        @change="onChange"
    >
        <header class="image-popup__modal-header">
            <b-button-close @click="close" />
        </header>
        <div class="image-popup__body">
            <slot><img :src="src"/></slot>
        </div>
    </b-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        size: {
            type: String,
            default: 'lg',
        },
        src: {
            type: String,
        },
    },
    watch: {
        value() {
            this.showModal = this.value
        },
    },
    data() {
        return {
            showModal: false,
        }
    },
    destroyed() {
        this.showModal = false
        this.onChange()
    },
    methods: {
        onChange() {
            this.$emit('input', this.showModal)
        },
        close() {
            this.showModal = false
            this.onChange()
        },
    },
}
</script>

<style lang="scss">
.image-popup__modal {
    .modal-content {
        margin: 0 auto;
    }
    .image-popup__body {
        img {
            object-fit: contain;
            width: 100%;
        }
    }
}
</style>

<style scoped>
div[id$='modal_outer_'] {
    z-index: 4000 !important;
}
</style>
