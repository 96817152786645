export default function({projectId, showBillingAccount}) {
    return [
        {
            title: 'Dashboard',
            href: `/p/${projectId}/dashboard`,
            icon: 'fa fa-home',
        },
        {
            title: 'Workflow',
            href: `/p/${projectId}/workflow`,
            icon: 'fa fa-code-fork',
        },
        {
            title: 'Collect',
            href: `/p/${projectId}/collect`,
            icon: 'icon-equalizer',
        },
        {
            title: 'Process',
            href: `/p/${projectId}/process`,
            icon: 'icon-graph',
        },
        {
            title: 'Smart Data',
            icon: 'icon-layers',
            class: 'nav-item-expandable',
            child: [
                {
                    title: 'Query Jobs',
                    href: `/p/${projectId}/reports/queryjobs/list`,
                    icon: 'fa fa-gears',
                },
                {
                    title: 'Reports',
                    href: `/p/${projectId}/reports/dataproviders/list`,
                    icon: 'icon-note',
                },
                {
                    title: 'Data Studio',
                    href: `/p/${projectId}/reports/datastudio/list`,
                    icon: 'icon-chart',
                },
            ],
        },
        {
            title: 'Settings',
            icon: 'icon-user-following',
            class: 'nav-item-expandable',
            child: [
                {
                    title: 'External services',
                    href: `/p/${projectId}/iam/tokens`,
                    icon: 'fa fa-external-link-square',
                },
                {
                    title: 'Manage access',
                    href: `/p/${projectId}/iam/permission`,
                    icon: 'icon-user-follow',
                },
                {
                    title: 'Projects',
                    href: `/p/${projectId}/iam/projects`,
                    icon: 'icon-people',
                },
                {
                    title: 'Quotas',
                    href: `/p/${projectId}/iam/quotas`,
                    icon: 'fa fa-pause',
                },
            ],
        },
        showBillingAccount
            ? {
                  title: 'Billing',
                  href: `/p/${projectId}/billing`,
                  icon: 'fa fa-money',
              }
            : null,
    ].filter(Boolean)
}
