<template>
    <transition name="slide-down">
        <div
            class="wit-banner-notification"
            :class="{[`wit-banner-notification--${content.variant}`]: true}"
            v-if="show"
        >
            <div class="wit-banner-notification__icon-left">
                <i class="fa fa-exclamation" />
            </div>
            <div class="wit-banner-notification__text">
                <div class="wit-banner-notification__text-header">{{ content.header }}</div>
                <div class="wit-banner-notification__text-description">
                    {{ substituteVariables(content.description) }}
                    <span class="wit-banner-notification__text-buttons">
                        <component
                            v-for="blokComponent in content.buttons"
                            :key="blokComponent._uid"
                            :blok="blokComponent"
                            :is="blokComponent.component"
                            :variant="content.variant"
                            :substitute-variables="substituteVariables"
                        ></component>
                    </span>
                </div>
            </div>
            <div class="wit-banner-notification__icon-close pointer-action">
                <i class="fa fa-close" @click="close" />
            </div>
        </div>
    </transition>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    components: {
        NotificationButton: () => import('./NotificationButton.vue'),
    },
    data() {
        return {
            show: false,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),
        content() {
            return this.notification.content
        },
        variables() {
            return {
                nodeApiUrl: process.env.VUE_APP_NODE_API_HOST,
                defaultTableExpiration: this.activeProject.expiration
                    ? parseInt(
                          Math.floor(this.activeProject.expiration.defaultTableExpirationMs / (24 * 60 * 60 * 1000))
                      )
                    : null,
                defaultPartitionExpiration: this.activeProject.expiration
                    ? parseInt(
                          Math.floor(this.activeProject.expiration.defaultPartitionExpirationMs / (24 * 60 * 60 * 1000))
                      )
                    : null,
            }
        },
    },
    mounted() {
        const closed = sessionStorage.getItem(`${this.activeProject.id}/${this.notification.slug}`)
        if (!closed) {
            this.show = true
        }
    },
    methods: {
        close() {
            sessionStorage.setItem(`${this.activeProject.id}/${this.notification.slug}`, true)
            this.show = false
        },

        substituteVariables(text) {
            return Object.keys(this.variables).reduce((acc, key) => {
                return acc.replace(new RegExp(`{{${key}}}`, 'g'), this.variables[key])
            }, text)
        },
    },
}
</script>

<style lang="scss">
.wit-banner-notification {
    display: grid;
    grid-template-columns: 1.5rem auto 1.5rem;
    align-items: center;
    padding: 0.4rem 1rem;

    .wit-banner-notification__text {
        .wit-banner-notification__text-header {
            font-weight: 600;
        }
    }

    .wit-banner-notification__icon-close {
        i {
            float: right;
            padding: 5px;
        }
    }

    .wit-banner-notification__text-buttons {
        margin: 0 -2.5px;
        .wit-notification-button {
            margin: 0 2.5px;
        }
    }
}

.wit-banner-notification--warning {
    background: #fff3cd;
    color: #856404;
}

.wit-banner-notification--danger {
    background: #f8d7da;
    color: #721c24;
}

.slide-down-enter-to,
.slide-down-leave {
    max-height: 900px;
    overflow: hidden;
    opacity: 1;
}

.slide-down-enter,
.slide-down-leave-to {
    overflow: hidden;
    max-height: 0;
    opacity: 0;
}

.slide-down-enter-active {
    transition: all 0.2s ease-in;
}

.slide-down-leave-active {
    transition: all 0.2s ease-out;
}
</style>
