<template>
    <div class="app" v-bind:class="{'wit-tutorial__page-locked': pageLocked}">
        <AppHeader fixed>
            <b-link class="navbar-brand" to="/" v-bind:class="{'page-locked': projectLocked}">
                <img
                    class="navbar-brand-full"
                    src="../../src/assets/img/witcloud-logo.png"
                    width="165"
                    height="45"
                    alt="Witcloud Logo"
                />
            </b-link>
            <div id="container-project-col">
                <wit-select
                    v-model="project"
                    label="name"
                    placeholder="Select project"
                    :calculatePosition="calculatePosition"
                    :options="projectOptions"
                    @open="onSelectProjectOpen"
                    :allowEmpty="false"
                    id="wit-select-project"
                ></wit-select>
                <project-router-link to="/iam/projects/create" id="create-project-icon">
                    <i class="fa fa-plus-circle"></i>
                </project-router-link>
                <b-tooltip
                    target="create-project-icon"
                    triggers="hover"
                    custom-class="create-project-icon__tooltip"
                    placement="bottom"
                >
                    Create new project
                </b-tooltip>
            </div>
            <b-col v-if="trialAccount" class="activate-col">
                <div>
                    <project-router-link to="/billing?t=details">
                        <b-button variant="warning">
                            <span class="activate-col__add-billing-text activate-col__add-billing-text--desktop"
                                >Add billing info</span
                            >
                            <span class="activate-col__add-billing-text activate-col__add-billing-text--mobile"
                                >Billing</span
                            >
                        </b-button>
                    </project-router-link>
                </div>
                <div class="small-info-text" id="trial-ends-text">
                    <div class="small-info-text--desktop">
                        <template v-if="countdown > 0">
                            Trial ends in
                            <vue-countdown :time="countdown">
                                <template v-slot="props">
                                    <template v-if="Number(props.days) > 0">
                                        <template v-if="Number(props.hours) > 0">
                                            {{ props.days }} {{ Number(props.days) > 1 ? 'days' : 'day' }},
                                        </template>
                                        <template v-else>
                                            {{ props.days }} {{ Number(props.days) > 1 ? 'days' : 'day' }}
                                        </template>
                                    </template>
                                    <template v-if="Number(props.hours) > 0">
                                        <template v-if="Number(props.minutes) > 0 && Number(props.days) === 0">
                                            {{ props.hours }}
                                            {{ Number(props.hours) > 1 ? 'hours' : 'hour' }},
                                        </template>
                                        <template v-else>
                                            {{ props.hours }}
                                            {{ Number(props.hours) > 1 ? 'hours' : 'hour' }}
                                        </template>
                                    </template>
                                    <template v-if="Number(props.minutes) > 0 && Number(props.days) === 0">
                                        {{ props.minutes }}
                                        {{ Number(props.minutes) > 1 ? 'minutes' : 'minute' }}
                                    </template>
                                </template>
                            </vue-countdown>
                        </template>
                        <template v-else> Trial has ended </template>
                    </div>
                    <div class="small-info-text--mobile" id="trial-ends-tooltip-trigger">
                        <i class="icon-info" />
                    </div>
                </div>
            </b-col>
            <b-col v-if="activeBillingAccount && !activeBillingAccount.active && !trialAccount" class="activate-col">
                <project-router-link to="/billing?t=subscription">
                    <b-button variant="warning">Activate</b-button>
                </project-router-link>
            </b-col>

            <b-navbar-nav class="d-md-down-none ml-auto">
                <div id="global-search-col">
                    <GlobalSearch />
                </div>
                <b-nav-item class="d-md-down-none">
                    <DefaultHeaderDropdown />
                </b-nav-item>
            </b-navbar-nav>
        </AppHeader>
        <div class="app-body">
            <DefaultSidebar ref="defaultSidebar" :projectLocked="projectLocked" />

            <main class="main" v-bind:style="mainStyle">
                <DefaultNotifications />
                <Breadcrumb :list="list" class="default-breadcrumb" v-bind:class="{'page-locked': projectLocked}" />
                <div class="container-fluid"><router-view :key="$route.path"> </router-view></div>
            </main>

            <DefaultAside ref="aside" :projectLocked="projectLocked" />
        </div>
        <div class="wit-padding" v-if="addPadding" />

        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
        <WitTutorial />
        <b-tooltip target="trial-ends-tooltip-trigger" triggers="click" custom-class="trial-ends-tooltip">
            <template v-if="countdown > 0">
                Trial ends in
                <vue-countdown :time="countdown">
                    <template v-slot="props">
                        <template v-if="Number(props.days) > 0">
                            <template v-if="Number(props.hours) > 0">
                                {{ props.days }} {{ Number(props.days) > 1 ? 'days' : 'day' }},
                            </template>
                            <template v-else> {{ props.days }} {{ Number(props.days) > 1 ? 'days' : 'day' }} </template>
                        </template>
                        <template v-if="Number(props.hours) > 0">
                            <template v-if="Number(props.minutes) > 0 && Number(props.days) === 0">
                                {{ props.hours }}
                                {{ Number(props.hours) > 1 ? 'hours' : 'hour' }},
                            </template>
                            <template v-else>
                                {{ props.hours }}
                                {{ Number(props.hours) > 1 ? 'hours' : 'hour' }}
                            </template>
                        </template>
                        <template v-if="Number(props.minutes) > 0 && Number(props.days) === 0">
                            {{ props.minutes }}
                            {{ Number(props.minutes) > 1 ? 'minutes' : 'minute' }}
                        </template>
                    </template>
                </vue-countdown>
            </template>
            <template v-else> Trial has ended </template>
        </b-tooltip>
    </div>
</template>

<script>
import '@stripe/stripe-js'

import EventBus from '../eventBus'

import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback'
import GlobalSearch from '@/components/Search/GlobalSearch'
import Loading from '@/components/loading'
import WitTutorial from '@/components/WitTutorial'

import {Header as AppHeader, AsideToggler, Footer as TheFooter, Breadcrumb} from '@coreui/vue'
import DefaultAside from './DefaultAside'
import DefaultHeaderDropdown from './DefaultHeaderDropdown'
import DefaultNotifications from './DefaultNotifications'
import DefaultSidebar from './DefaultSidebar'

export default {
    components: {
        AsideToggler,
        AppHeader,
        TheFooter,
        Breadcrumb,
        DefaultAside,
        DefaultHeaderDropdown,
        DefaultNotifications,
        DefaultSidebar,
        Feedback,
        GlobalSearch,
        Loading,
        WitTutorial,
        VueCountdown: () => import('@chenfengyuan/vue-countdown'),
    },
    notifications: {
        projectSet: {
            type: 'success',
            timeout: 8000,
        },
        projectNotFound: {
            type: 'error',
            timeout: 8000,
        },
        routeNotFound: {
            type: 'error',
            timeout: 8000,
        },
    },
    beforeCreate() {
        this.$errorHandler.setUser(this.$auth.user().id)
    },
    created() {
        try {
            this.$store.dispatch('project/getProjects')
            this.processQuery()
        } catch {}
    },
    methods: {
        calculatePosition(dropdownList, component, {width, top, left}) {
            dropdownList.style.top = '45px'
            dropdownList.style.left = left
            dropdownList.style.width = width
            dropdownList.style.position = 'fixed'
            dropdownList.classList.add('wit-select-project__list')
        },

        onSelectProjectOpen() {
            this.$store.dispatch('project/getProjects')
        },

        processQuery() {
            const {projectSet, projectNotFound, routeNotFound, login, ...query} = this.$route.query

            if (Object.keys(this.$route.query).length > Object.keys(query).length) {
                this.$router.replace({query})

                if (login) {
                    this.$store.commit('loading/PROCESSED')
                }

                if (projectSet) {
                    this.projectSet({
                        message: `Active project set to '${this.project.name}'`,
                    })
                }

                if (projectNotFound) {
                    this.projectNotFound({
                        message: `Project ${projectNotFound} was not found or access was not granted`,
                    })
                }

                if (routeNotFound) {
                    this.routeNotFound({message: `Could not navigate to desired path`})
                }
            }
        },
    },
    computed: {
        ...mapGetters({
            projects: 'project/projects',
            projectLocked: 'project/locked',
            sidebarWidth: 'sidebar/width',
            sidebarWidthCollapsed: 'sidebar/widthCollapsed',
            sidebarResizing: 'sidebar/resizing',
            sidebarCollapsed: 'sidebar/collapsed',
            asideWidth: 'aside/width',
            asideWidthCollapsed: 'aside/widthCollapsed',
            asideResizing: 'aside/resizing',
            asideCollapsed: 'aside/collapsed',
            activeBillingAccount: 'billing/active',
            billingAccounts: 'billing/accounts',
            trialAccount: 'billing/trialAccount',
            addPadding: 'tutorial/addPadding',
            pageLocked: 'tutorial/pageLocked',
        }),

        list() {
            return this.$route.matched.filter((route) => route.name || route.meta.label)
        },

        project: {
            get() {
                return this.$store.state.project.active
            },

            async set(value) {
                this.$store.commit('loading/PROCESSING', `Setting active project...`)
                await this.$store.dispatch('project/setProject', value)
                EventBus.$emit('projectChange', true)
                if (value && value.type !== 'SANDBOX' && !value.authorized) {
                    this.$projectRouter.push(`/iam/projects/create?id=${value.id}`)
                } else {
                    this.$router.push(`/p/${value.id}/dashboard`, () => this.$store.commit('loading/PROCESSED'))
                }
            },
        },

        projectOptions() {
            return this.projects.sort((a, b) => a.name.localeCompare(b.name))
        },

        mainStyle() {
            let style = {}
            const transition = []

            style.marginLeft = this.sidebarCollapsed ? this.sidebarWidthCollapsed : this.sidebarWidth
            if (!this.sidebarResizing) transition.push('margin-left 0.3s ease')

            style.marginRight = this.asideCollapsed ? this.asideWidthCollapsed : this.asideWidth
            if (!this.asideResizing) transition.push('margin-right 0.3s ease')

            style.transition = transition.length > 0 ? transition.join(', ') : 'none'

            return style
        },

        countdown() {
            if (this.trialAccount) {
                return new Date(this.trialAccount.trialEnd).getTime() - new Date().getTime()
            } else return null
        },

        projectPath() {
            const {path} = this.$route
            const noSlashPath = path.substr(0, 1) === '/' ? path.substr(1) : path
            const [p, projectId, ..._path] = noSlashPath.split('/')

            return `/${_path.join('/')}`
        },
    },
    watch: {
        $route() {
            this.processQuery()
        },
    },
}
</script>
