<template>
    <div :id="id" :class="{'global-search': true, 'global-search--open': open}">
        <search-input
            v-model="query"
            @input="onQueryInput"
            :loading="loading"
            :open.sync="open"
            @click="onInputClick"
        />
        <search-results
            :query="query"
            :result="resultAggregate"
            :open.sync="open"
            :position="position"
            :loading="loading"
            label="name"
            @scrollEnd="onScrollEnd"
        />
    </div>
</template>

<script>
import SearchInput from './SearchInput'
import SearchResults from './SearchResults'

export default {
    components: {
        SearchInput,
        SearchResults,
    },
    data() {
        return {
            id: this.$randomId(),
            query: '',
            length: 10,
            offset: 0,
            queryTimeout: null,
            result: [],
            resultEnd: false,
            left: 0,
            width: 0,
            open: false,
            loading: false,
        }
    },
    computed: {
        position() {
            return {
                top: '45px',
                left: `${this.left}px`,
                width: `${this.width}px`,
                position: 'fixed',
            }
        },

        resultAggregate() {
            return this.result.map(el => el.hits.map(hit => ({...hit, index: el.index}))).flat()
        },
    },
    mounted() {
        const el = document.getElementById(this.id)
        const rect = el.getBoundingClientRect()

        this.left = rect.left
        this.width = rect.width
    },
    methods: {
        onQueryInput() {
            if (this.queryTimeout) {
                clearTimeout(this.queryTimeout)
            }

            this.queryTimeout = setTimeout(() => {
                this.result = []
                this.resultEnd = false
                this.offset = 0
                this.open = false

                if (this.query) {
                    this.queryRequest()
                }
            }, 500)
        },

        onInputClick() {
            if (this.result.length) {
                this.open = true
            }
        },

        async queryRequest() {
            if (!this.resultEnd) {
                this.loading = true

                const response = await this.axios.get(`${process.env.VUE_APP_SEARCH_HOST}/search`, {
                    params: {query: this.query, length: this.length, offset: this.offset},
                })

                this.result = [...this.result, ...response.data]
                this.offset = this.offset + this.length
                this.resultEnd = this.result.every(el => el.nbHits <= this.offset)

                this.calculatePosition()
                this.loading = false
                this.open = true
            }
        },

        onScrollEnd() {
            this.queryRequest()
        },

        calculatePosition() {
            const el = document.getElementById(this.id)
            const rect = el.getBoundingClientRect()

            this.left = rect.left
            this.width = rect.width
        },
    },
}
</script>

<style lang="scss">
.global-search {
    .search-input__wrapper {
        min-width: 100px;
        max-width: 300px;
    }

    &.global-search--open {
        .search-input {
            border-bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            padding-bottom: calc(0.375rem + 1px);
        }
    }
}
</style>
