<template>
    <div class="wit-default-sidebar">
        <sidebar-menu
            :menu="menu"
            :collapsed="collapsed"
            :width="width"
            :widthCollapsed="widthCollapsed"
            v-bind:class="{'page-locked': projectLocked, 'wit-default-sidebar__sidebar-resizing': resizing}"
            @toggle-collapse="onToggleCollapse"
        >
            <template v-slot:dropdown-icon><i class="icon-arrow-down" /></template>
            <template v-slot:toggle-icon>
                <div class="wit-default-sidebar__toggle-wrapper">
                    <i class="fa" v-bind:class="{'fa-chevron-right': collapsed, 'fa-chevron-left': !collapsed}" />
                </div>
            </template>
        </sidebar-menu>
        <div id="wit-default-sidebar__handle" v-bind:style="handleStyle" />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {SidebarMenu} from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import nav from '@/_nav'

export default {
    props: {
        projectLocked: {
            type: Boolean,
        },
    },
    components: {
        SidebarMenu,
    },
    data() {
        return {
            startOffset: 0,
            minWidth: 150,
            maxWidth: 300,
        }
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
        }),

        collapsed: {
            get() {
                return this.$store.state.sidebar.collapsed
            },

            set(value) {
                this.$store.commit('sidebar/SET_COLLAPSED', value)
            },
        },

        resizing: {
            get() {
                return this.$store.state.sidebar.resizing
            },

            set(value) {
                this.$store.commit('sidebar/SET_RESIZING', value)
            },
        },

        width: {
            get() {
                return this.$store.state.sidebar.width
            },

            set(value) {
                this.$store.commit('sidebar/SET_WIDTH', value)
            },
        },

        widthCollapsed: {
            get() {
                return this.$store.state.sidebar.widthCollapsed
            },

            set(value) {
                this.$store.commit('sidebar/SET_WIDTH_COLLAPSED', value)
            },
        },

        handleStyle() {
            let style = {}

            if (this.collapsed) {
                style.display = 'none'
                style.visibility = 'hidden'
            } else {
                style.marginLeft = this.width
            }

            return style
        },

        menu() {
            return nav({
                projectId: this.activeProject ? this.activeProject.id : '',
                showBillingAccount: this.$store.state.billing.active,
            })
        },
    },
    watch: {
        projectLocked() {
            if (this.projectLocked) {
                this.collapsed = true
            }
        },
    },
    mounted() {
        if (this.projectLocked) {
            this.collapsed = true
        }

        const handle = document.getElementById('wit-default-sidebar__handle')
        handle.addEventListener('mousedown', (e) => {
            this.resizing = true
            this.startOffset = handle.offsetWidth
        })

        document.addEventListener('mousemove', (e) => {
            if (this.resizing) {
                e.preventDefault()

                const width = this.startOffset + e.pageX
                if (width < this.minWidth) {
                    this.width = this.minWidth + 'px'
                } else if (width > this.maxWidth) {
                    this.width = this.maxWidth + 'px'
                } else {
                    this.width = width + 'px'
                }
            }
        })

        document.addEventListener('mouseup', (e) => {
            this.resizing = false
        })
    },
    methods: {
        showIssueModal() {
            this.$emit('showIssueModal')
        },

        onToggleCollapse(collapsed) {
            this.collapsed = collapsed

            if (!collapsed) {
                this.$store.commit('aside/SET_COLLAPSED', true)
            }
        },
    },
}
</script>

<style lang="scss">
.wit-default-sidebar {
    z-index: 3000;
    display: flex;
    flex-flow: row nowrap;
    transition: 0.3s max-width ease;

    .v-sidebar-menu {
        margin-top: 55px;
        height: calc(100vh - 55px);
        background-color: white;

        .vsm--link,
        .vsm--link_level-2 {
            color: #29363d;
            text-decoration: none;
            padding: 0.75rem 1rem;
            font-size: 0.875rem;
            line-height: 1.5;
            background-color: transparent;
            box-shadow: none;

            i.vsm--icon {
                background-color: transparent;
                width: 1.09375rem;
                margin: 0 0.5rem 0 0;
                font-size: 0.875rem;
                text-align: center;
                color: #536c79;
                height: 20px;
                line-height: normal;

                display: flex;
                justify-content: center; /* Wyśrodkowanie poziome */
                align-items: center; /* Wyśrodkowanie pionowe */
            }
        }

        .vsm--item_open {
            .vsm--link {
                background-color: transparent;
                color: #29363d;
                font-weight: 500;
                i.vsm--icon {
                    background-color: transparent;
                    color: #536c79;
                    font-weight: 500;
                }
            }
        }

        .vsm--list {
            background-color: transparent;
            padding: 0;
            .vsm--link_level-2 {
                padding-left: 25px;
            }

            .vsm--link_active:not(.nav-item-expandable),
            .vsm--link.active:not(.nav-item-expandable) {
                box-shadow: none;
                background-color: #f2b929;
                color: white;
                font-weight: 600;

                i.vsm--icon {
                    color: white;
                    font-weight: 600;
                }
            }
        }

        .vsm--link_active:not(.nav-item-expandable),
        .vsm--link.active:not(.nav-item-expandable) {
            box-shadow: none;
            background-color: #f2b929;
            color: white;
            font-weight: 600;

            i.vsm--icon {
                color: white;
                font-weight: 600;
            }
        }

        .vsm--link_hover,
        .vsm--link:hover {
            font-weight: 600;
            transition: none;
            background-color: transparent;

            i.vsm--icon {
                font-weight: 600;
                transition: none;
                background-color: transparent !important;
                color: #536c79 !important;
            }
        }

        .vsm--link_hover.vsm--link_active:not(.nav-item-expandable),
        .vsm--link.vsm--link_active:not(.nav-item-expandable):hover {
            background-color: #f2b929 !important;

            i.vsm--icon {
                background-color: #f2b929 !important;
                color: white !important;
            }
        }

        .vsm--arrow {
            i {
                font-size: 8px;
                line-height: 8px;
            }
        }

        .vsm--toggle-btn {
            background-color: #29363d;
            height: 45px;

            .wit-default-sidebar__toggle-wrapper {
                width: 38px;
                transition: all 0.3s ease;
            }
        }

        .vsm--mobile-item {
            max-width: 250px !important;
            .vsm--mobile-bg {
                background-color: #f2b929 !important;
            }
            .vsm--dropdown_mobile-item {
                background-color: white;
                overflow-y: hidden !important;
            }
        }

        .wit-default-sidebar__sidebar-footer {
            padding: 0.75rem 1rem;
            transition: all 0.3s ease;
        }

        *:focus {
            outline: none;
        }
    }

    .vsm_collapsed {
        .vsm--item_open {
            .vsm--link_active {
                background-color: #f2b929;

                i.vsm--icon {
                    background-color: #f2b929;
                    color: white;
                }
            }

            .vsm--link_active:hover {
                background-color: #f2b929 !important;

                i.vsm--icon {
                    background-color: #f2b929 !important;
                    color: white !important;
                }
            }
        }
    }

    .vsm_expanded {
        .vsm--toggle-btn {
            .wit-default-sidebar__toggle-wrapper {
                float: right;
            }
        }
    }

    .wit-default-sidebar__sidebar-resizing {
        transition: none;
    }

    #wit-default-sidebar__handle {
        position: fixed;
        height: 100%;
        border-right: 5px solid transparent;
        margin-right: -5px;
        z-index: 3555;
        float: right;
        transition: margin-left 0.3s ease;
        cursor: ew-resize;
    }
}
</style>
