<template>
    <div class="wit-aside-right">
        <div id="wit-aside-right__handle" v-bind:style="handleStyle" />
        <sidebar-menu
            :menu="menu"
            :collapsed="collapsed"
            :width="width"
            :widthCollapsed="widthCollapsed"
            @toggle-collapse="onToggleCollapse"
            @item-click="onItemClick"
            disable-hover
            class="wit-aside-right__sidebar"
            v-bind:class="{
                'page-locked': projectLocked,
                'wit-aside-right__hide-nav': !collapsed && (showHelp || showTutorials),
                'wit-aside-right__sidebar-resizing': resizing,
            }"
        >
            <template v-slot:header>
                <div class="aside-uncollapsed" v-if="!collapsed && (showHelp || showTutorials)">
                    <div class="aside-help" v-if="!collapsed && showHelp">
                        <div class="aside-help__nav">
                            <div @click="hideHelp" class="aside-help__nav-item">
                                <i class="fa fa-angle-double-left" /> Close Help
                            </div>
                            <div v-if="showCodelab">
                                <div class="aside-help__nav-item" @click="goBack">
                                    <i class="fa fa-angle-left" /> Go back
                                </div>
                                <div class="aside-help__nav-item">
                                    <a :href="codelabUrl" target="_blank">
                                        <i class="fa fa-external-link" /> Open in new tab
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="aside-help__body">
                            <div v-if="!showCodelab" class="aside-help__list">
                                <div
                                    class="aside-help__list-single"
                                    v-for="(category, index) in Object.keys(docTree)"
                                    :key="index"
                                >
                                    <h5 class="mt-0">{{ category }}</h5>
                                    <ul>
                                        <li v-for="(codelab, _index) in docTree[category]" :key="_index">
                                            <h6 class="mt-0 pointer-action">
                                                <span @click="() => openCodelab(codelab.link)">{{ codelab.name }}</span>
                                            </h6>
                                        </li>
                                        <br />
                                    </ul>
                                </div>
                            </div>
                            <div v-else class="aside-help__codelab">
                                <div class="aside-help__codelab-iframe-wrapper">
                                    <iframe
                                        :src="codelabUrl"
                                        :width="iFrameWidth"
                                        :height="iFrameHeight"
                                        @load="iframeLoaded(codelabUrl)"
                                        ref="codelabIframe"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                        <ImagePopup v-model="showImgPopup" :src="imgSrc" @input="onChange" />
                    </div>
                </div>

                <div v-else class="aside-help__height"></div>
            </template>

            <template v-slot:toggle-icon>
                <div class="aside-help__toggle-wrapper">
                    <i class="fa" v-bind:class="{'fa-chevron-left': collapsed, 'fa-chevron-right': !collapsed}" />
                </div>
            </template>
        </sidebar-menu>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {SidebarMenu} from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import ImagePopup from '@/components/ImagePopup.vue'
import eventBus from '../eventBus'

import {fromEntries} from '@/shared/fromEntries'

export default {
    components: {
        SidebarMenu,
        ImagePopup,
    },
    props: {
        value: {
            type: Boolean,
        },
        projectLocked: {
            type: Boolean,
        },
    },
    data() {
        return {
            codelabUrl: '',
            showCodelab: false,
            iFrameWidth: 0,
            iFrameHeight: 0,
            showImgPopup: false,
            imgSrc: '',
            startOffset: 0,
            minWidth: 150,
            maxWidth: 750,
            showHelp: false,
            showTutorials: false,
            tutorialIcon: 'icon-notebook',
            toggledQuickHelp: false,
        }
    },
    async created() {
        const spaceRes = await this.$storyblok.get(`cdn/spaces/me`)
        const cacheVersion = spaceRes.data.space.version

        const {
            data: {story},
        } = await this.$storyblok.get(`cdn/stories/docs`, {
            cv: cacheVersion,
            version: ['development', 'local'].includes(process.env.NODE_ENV) ? 'draft' : 'published',
        })

        const _docs = []
        this.docTree = fromEntries(
            story.content.tree.map((category) => [
                category.name,
                category.docs.map((doc) => {
                    const _doc = {
                        name: doc.name,
                        link: doc.url,
                        witcloudPath: doc.witcloudPath.split(','),
                    }
                    _docs.push(_doc)
                    return _doc
                }),
            ])
        )
        this.docs = _docs

        setInterval(this.animateTutorial, 1500)
    },
    mounted() {
        if (this.projectLocked) {
            this.collapsed = true
        }

        window.addEventListener('resize', this.handleResize)
        window.addEventListener('message', this.handleImageClicked)

        const handle = document.getElementById('wit-aside-right__handle')
        handle.addEventListener('mousedown', (e) => {
            this.resizing = true
            this.startOffset = handle.offsetWidth
        })

        document.addEventListener('mousemove', (e) => {
            if (this.resizing) {
                e.preventDefault()

                const width = window.innerWidth - e.pageX - this.startOffset
                if (width < this.minWidth) {
                    this.width = this.minWidth + 'px'
                } else if (width > this.maxWidth) {
                    this.width = this.maxWidth + 'px'
                } else {
                    this.width = width + 'px'
                }

                this.handleResize()
            }
        })

        document.addEventListener('mouseup', (e) => {
            if (this.resizing) {
                eventBus.$emit('triggerHighlight')
            }
            this.resizing = false
        })

        eventBus.$on('openQuickHelp', this.openQuickHelp)
        eventBus.$on('openHelp', this.openHelp)
        eventBus.$on('toggleHelp', this.toggleHelp)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize)
        window.removeEventListener('message', this.handleImageClicked)
        eventBus.$off('openQuickHelp', this.openQuickHelp)
        eventBus.$off('openHelp', this.openHelp)
        eventBus.$off('toggleHelp', this.toggleHelp)
    },
    computed: {
        ...mapGetters({
            quickHelp: 'tutorial/quickHelp',
            showQuickHelp: 'tutorial/showQuickHelp',
            tutorialStep: 'tutorial/tutorialStep',
            tutorials: 'tutorial/tutorials',
            steps: 'tutorial/steps',
        }),

        docs: {
            get() {
                return this.$store.state.tutorial.docs
            },

            set(value) {
                this.$store.commit('tutorial/SET_DOCS', value)
            },
        },

        docTree: {
            get() {
                return this.$store.state.tutorial.docTree
            },

            set(value) {
                this.$store.commit('tutorial/SET_DOCTREE', value)
            },
        },

        collapsed: {
            get() {
                return this.$store.state.aside.collapsed
            },

            set(value) {
                this.$store.commit('aside/SET_COLLAPSED', value)
            },
        },

        resizing: {
            get() {
                return this.$store.state.aside.resizing
            },

            set(value) {
                this.$store.commit('aside/SET_RESIZING', value)
            },
        },

        width: {
            get() {
                return this.$store.state.aside.width
            },

            set(value) {
                this.$store.commit('aside/SET_WIDTH', value)
            },
        },

        widthCollapsed: {
            get() {
                return this.$store.state.aside.widthCollapsed
            },

            set(value) {
                this.$store.commit('aside/SET_WIDTH_COLLAPSED', value)
            },
        },

        startSlug: {
            get() {
                return this.$store.state.tutorial.startSlug
            },

            set(value) {
                this.$store.commit('tutorial/SET_STARTSLUG', value)
            },
        },

        handleStyle() {
            let style = {}

            if (this.collapsed) {
                style.display = 'none'
                style.visibility = 'hidden'
            } else {
                style.marginLeft = '-' + this.width
            }

            return style
        },

        menu() {
            const menu = [
                {
                    id: 'help',
                    title: 'Help',
                    icon: 'fa fa-question',
                },
            ]

            return menu
        },
    },
    watch: {
        $route() {
            if (this.toggledQuickHelp) {
                this.onToggleCollapse(true)
                this.goBack()
                this.toggledQuickHelp = false
            }
        },

        steps() {
            if (this.steps.length === 0) {
                this.collapsed = true
            }
        },

        projectLocked() {
            if (this.projectLocked) {
                this.collapsed = true
            }
        },

        collapsed() {
            setTimeout(() => eventBus.$emit('triggerHighlight'), 300)
        },
    },
    methods: {
        iframeLoaded(docsPath) {
            this.$gtm.trackEvent({
                event: 'content-view',
                docsPath,
            })
        },
        onItemClick(event, item, node) {
            if (item.id === 'help') {
                this.openHelp()
            }
        },

        openQuickHelp() {
            this.openCodelab(this.quickHelp)
            this.toggledQuickHelp = true
            this.collapsed = false
            this.onShowHelp()
        },

        onShowHelp() {
            this.showTutorials = false
            this.showHelp = true
            this.width = '375px'
            this.handleResize()
        },

        onChange(value) {
            this.showImgPopup = value
        },

        hideHelp() {
            this.toggledQuickHelp = false
            this.showHelp = false
        },

        async handleResize() {
            if (this.showCodelab) {
                await new Promise((resolve) => {
                    this.interval = setInterval((interval) => {
                        if (this.$refs.codelabIframe) {
                            clearInterval(this.interval)
                            setTimeout(resolve, 300)
                        }
                    }, 100)
                })
            }

            const [aside] = document.getElementsByClassName('wit-aside-right__sidebar')
            this.iFrameWidth = aside.clientWidth * 0.95
            this.iFrameHeight = Math.min(window.innerHeight * 0.83, aside.clientHeight * 0.83)
        },

        handleImageClicked(e) {
            try {
                this.showImgPopup = false

                const data = JSON.parse(e.data)
                if (data.src) {
                    this.imgSrc = data.src
                    this.showImgPopup = true
                }
            } catch {}
        },

        openCodelab(url) {
            this.codelabUrl = url
            this.showCodelab = true
            this.handleResize()
        },

        goBack() {
            this.toggledQuickHelp = false
            this.showCodelab = false
        },

        async onToggleCollapse(collapsed) {
            this.collapsed = collapsed

            if (!collapsed) {
                this.$store.commit('sidebar/SET_COLLAPSED', true)

                this.handleResize()
            }
        },

        animateTutorial() {
            this.tutorialIcon = this.tutorialIcon === 'icon-book-open' ? 'icon-notebook' : 'icon-book-open'
        },

        startTutorial(slug) {
            this.showTutorials = false
            this.startSlug = slug
        },

        openHelp() {
            this.collapsed = false
            this.onShowHelp()
        },

        toggleHelp() {
            this.onToggleCollapse(!this.collapsed)
        },
    },
}
</script>

<style lang="scss">
.icon-toggle-aside {
    height: 35px;
    width: 35px;
    margin-right: 3px;
}

.wit-aside-right {
    z-index: 3000;
    display: flex;
    flex-flow: row nowrap;
    transition: 0.3s max-width ease;

    .v-sidebar-menu {
        margin-top: 55px;
        height: calc(100vh - 55px);
        background-color: white;
        right: 0;
        left: initial;

        .vsm--link,
        .vsm--link_level-2 {
            color: #29363d;
            text-decoration: none;
            padding: 0.75rem 1rem;
            font-size: 0.875rem;
            line-height: 1.5;
            background-color: transparent;
            box-shadow: none;

            i.vsm--icon {
                background-color: transparent;
                width: 1.09375rem;
                margin: 0 0.5rem 0 0;
                font-size: 0.875rem;
                text-align: center;
                color: #536c79;
                height: auto;
                line-height: normal;
            }

            i.wit-aside-right__quick-help-icon {
                color: #f2b929;
                animation: 2s spin infinite;
            }
            i.wit-aside-right__tutorial-icon-yellow {
                color: #f2b929 !important;
            }
            i.wit-aside-right__tutorial-icon-red {
                color: red !important;
            }
        }

        .vsm--link_hover,
        .vsm--link:hover {
            font-weight: 600;
            transition: none;
            background-color: transparent;

            i.vsm--icon {
                font-weight: 600;
                transition: none;
                background-color: transparent !important;
                color: #536c79 !important;
            }

            i.wit-aside-right__quick-help-icon {
                color: #f2b929 !important;
            }
            i.wit-aside-right__tutorial-icon-yellow {
                color: #f2b929 !important;
            }
            i.wit-aside-right__tutorial-icon-red {
                color: red !important;
            }
        }

        .vsm--mobile-bg {
            background-color: #f2b929;
        }

        .vsm--toggle-btn {
            background-color: #29363d;
            height: 45px;

            .aside-help__toggle-wrapper {
                width: 38px;
                transition: all 0.3s ease;

                i {
                    z-index: 9000;
                }
            }
        }

        .vsm_expanded {
            .vsm--toggle-btn {
                .aside-help__toggle-wrapper {
                    float: left;
                }
            }
        }

        *:focus {
            outline: none;
        }
    }
}

.aside-uncollapsed {
    height: calc(100vh - 100px);
    overflow-y: scroll;

    .aside-help {
        .aside-help__nav {
            padding: 0 0.5rem;
            font-size: 0.85rem;
            color: #20a8d8;
            cursor: pointer;

            .aside-help__nav-item {
                margin-bottom: 3px;
                i {
                    width: 20px;
                }
                a,
                a:hover,
                a:active,
                a:visited {
                    text-decoration: none;
                }
            }

            .aside-help__nav-item:hover {
                font-weight: 600;
            }
        }

        .aside-help__body {
            padding: 0.5rem;
            width: 100%;
            min-height: 80vh;

            .aside-help__list {
                padding: 0 0.5rem;
                .aside-help__list-single {
                    h6:hover {
                        text-decoration: underline;
                    }
                    h6 {
                        color: #20a8d8;
                    }
                }
            }

            .aside-help__codelab {
                iframe {
                    border: 1px solid #f0f3f5;
                    border-radius: 5px;
                    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075);
                }
            }
        }
    }

    .aside-tutorial {
        .aside-tutorial__nav {
            padding: 0 0.5rem;
            font-size: 0.85rem;
            color: #20a8d8;
            cursor: pointer;

            .aside-tutorial__nav-item {
                margin-bottom: 3px;
                i {
                    width: 20px;
                }
                a,
                a:hover,
                a:active,
                a:visited {
                    text-decoration: none;
                }
            }

            .aside-tutorial__nav-item:hover {
                font-weight: 600;
            }
        }

        .aside-tutorial__list {
            padding: 0 0.5rem;
            h6:hover {
                text-decoration: underline;
            }
            h6 {
                color: #20a8d8;
            }
        }

        .aside-tutorial__body {
            padding: 0.5rem;
            width: 100%;
            min-height: 80vh;
        }
    }
}

.aside-uncollapsed::-webkit-scrollbar-track {
    background-color: #0000000d;
}

.aside-uncollapsed::-webkit-scrollbar {
    width: 4px;
    background-color: #0000000d;
}

.aaside-uncollapsed::-webkit-scrollbar-thumb {
    background-color: #0000000d;
    border: 2px solid #0000000d;
}

#wit-aside-right__handle {
    position: fixed;
    height: 100%;
    border-left: 5px solid transparent;
    margin-right: -5px;
    z-index: 3555;
    float: left;
    transition: margin-right 0.3s ease;
    cursor: ew-resize;
}

.wit-aside-right__sidebar-resizing {
    transition: none;
}

.wit-aside-right__hide-nav {
    .vsm--scroll-wrapper {
        display: none;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
