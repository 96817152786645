<template>
    <div class="wit-banner-notifications">
        <banner-notification
            v-for="notification in validNotifications"
            :key="notification._uid"
            :notification="notification"
        />
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

import BannerNotification from '@/components/Notifications/BannerNotification.vue'

export default {
    components: {
        BannerNotification,
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            notifications: 'notification/all',
        }),
        validNotifications() {
            return this.notifications.filter(notification =>
                this.validateNotification(notification.content.condition, notification.content.conditionOperation)
            )
        },
    },
    created() {
        this.$store.dispatch('notification/fetchAll', this.$storyblok)
    },
    methods: {
        validateNotification(conditions, operation) {
            const conditionMap = conditions.map(condition => {
                if (condition === 'always') {
                    return true
                } else if (condition === 'in-sandbox') {
                    return this.activeProject.type === 'SANDBOX'
                } else if (condition === 'not-in-sandbox') {
                    return this.activeProject.type !== 'SANDBOX'
                } else if (condition === 'table-partition-expiration') {
                    return (
                        this.activeProject.expiration &&
                        this.activeProject.expiration.defaultTableExpirationMs &&
                        this.activeProject.expiration.defaultPartitionExpirationMs
                    )
                } else if (condition === 'table-expiration-only') {
                    return (
                        this.activeProject.expiration &&
                        this.activeProject.expiration.defaultTableExpirationMs &&
                        !this.activeProject.expiration.defaultPartitionExpirationMs
                    )
                } else if (condition === 'partition-expiration-only') {
                    return (
                        this.activeProject.expiration &&
                        this.activeProject.expiration.defaultPartitionExpirationMs &&
                        !this.activeProject.expiration.defaultTableExpirationMs
                    )
                }

                return false
            })

            if (operation === 'all') {
                return conditionMap.every(el => Boolean(el))
            } else {
                return conditionMap.some(el => Boolean(el))
            }
        },
    },
}
</script>
